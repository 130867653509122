html, body {
  margin: 0;
  padding: 0;
  max-height: 100vh;
}

body {
  font-family: "Thasadith", sans-serif;
  font-size: 1.4rem;
  min-width: 300px;
  max-width: 1280px;
  margin: 0 auto;
  overflow-x: hidden;
}

a {
  color: rgb(224, 102, 102);
  text-decoration: none;
  font-weight: bold;
}
a:hover {
  text-decoration: underline;
}

html, body, div#root {
  height: 100%;
}

div#wrapper {
  border-left: 6px solid black;
  border-right: 6px solid black;
  margin-left: -6px;
  margin-right: -6px;
}

div#loader {
  box-sizing: border-box;
  border: 24px solid black;
  background-image: url('../public/img/pexels-photo-3331837.avif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  height: 100vh;
}

div#content {
  box-sizing: border-box;
  padding: 40px 60px 60px 60px;
  border-top: 6px solid rgb(224, 102, 102);
  min-height: calc(100% - 366px);
  overflow-x: hidden;
}

header {
  text-align: right;
  padding: 60px;
  margin-right: 80px;
}

h1 {
  font-size: 2.8rem;
}

h2 {
  padding-top: 20px;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}

h3 {
  font-style: italic;
  padding-right: 40px;
}

h1, h3 {
  font-family: 'Playfair Display', serif;
  color: rgb(224, 102, 102);
  font-weight: 300;
}

div#banner {
  background-image: url('../public/img/road2.avif');
  background-repeat: no-repeat;
  aspect-ratio: 22/9;
  background-size: cover;
  margin: -70px -60px 60px -60px;
  border-bottom: 6px solid black;
  min-height: 360px;
  overflow-x: hidden;
}

div#banner a:hover {
  text-decoration: none;
}

div#banner h1 {
  padding-left: 10%;
  padding-top: 6%;
}

div.links {
  text-align: center;
  padding: 40px 0px 80px 0px;
}

div.links a, a.button {
  color: rgb(224, 102, 102);
  border: 2px solid rgb(224, 102, 102);
  padding: 6px 12px;
  text-decoration: none;
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
  margin: 6px;
  background-color: white;
  display: inline-block;
}

div.links a:hover, a.button:hover {
  color: white;
  background-color: rgb(224, 102, 102);
}

section {
  padding: 80px 0px;
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 40px;
  border-top: 2px dotted #ccc;
}

section img {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
  /* filter: grayscale(1); */
  border: 4px solid black;
}

section p {
  text-align: justify;
}

span.emphasis {
  color: rgb(224, 102, 102);
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}

footer {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 10px;
  color: white;
  background-color: black;
  font-size: 1.2rem;
  padding: 80px 60px;
  min-height: 200px;
  border-top: 6px solid rgb(224, 102, 102);
  font-family: 'Oswald', sans-serif;
}

footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

@media (max-width: 800px) {
  section {
    padding: 20px 0px !important;
    /* grid-gap: 10px; */
  }

  section:nth-child(odd) div:first-child {
    order: 2;
  }

  h2 {
    padding-top: 0px;
  }

  div.links {
    padding: 20px 0px 40px 0px;
  }

  div#content {
    padding: 10px 20px 20px 20px;
  }

  div#banner {
    margin: -40px -20px 20px -20px;
  }

  footer {
    padding: 40px 20px;
  }
}

@media (max-width: 640px) {
  section {
    grid-template-columns: 1fr;
  }
}